export default {
  se_sv: {
    title: 'Personligt stöd',
    text: 'Våra fysioterapeuter är certifierade experter på ledsmärta och hjälper dig att komma igång.',
    icons: [
      { text: 'Videosamtal',
        icon: 'video' },
      { text: 'Chatt',
        icon: 'comments' },
      { text: 'Telefon',
        icon: 'phone' },
    ],
    slides: [
      {
        image: 'pt-1',
        storybookSlide: 'https://via.placeholder.com/390x529?text=slide:1',
      },
      {
        image: 'pt-2',
        storybookSlide: 'https://via.placeholder.com/390x529?text=slide:2',
      },
      {
        image: 'pt-3',
        storybookSlide: 'https://via.placeholder.com/390x529?text=slide:3',
      },
      {
        image: 'pt-4',
        storybookSlide: 'https://via.placeholder.com/390x529?text=slide:4',
      },
      {
        image: 'pt-5',
        storybookSlide: 'https://via.placeholder.com/390x529?text=slide:5',
      },
    ],
    listing: [
      {
        title: 'Redo att hjälpa dig nå dina mål',
        li: [
          { text: 'Certifierade experter på ledsmärta' },
          { text: 'Tillgängliga alla dagar i veckan' },
          { text: 'Svarar på dina frågor via chatt' },
          { text: 'Motiverar dig till att nå dina mål' },
        ],
        buttonText: 'Träffa en fysioterapeut',
      },
    ],
  },
  us_en: {
    title: 'Personal guidance and support',
    text: 'Our physical therapists are certified experts on joint pain and will help you get started.',
    icons: [
      { text: 'Live video',
        icon: 'video' },
      { text: 'Messaging',
        icon: 'comments' },
      { text: 'Phone',
        icon: 'phone' },
    ],
    slides: [
      { image: 'pt-angelica', title: 'Angelica Ling', workTitle: 'Licensed physical therapist' },
      { image: 'pt-sven', title: 'Sven Thomsson', workTitle: 'Licensed physical therapist' },
      { image: 'pt-catherine', title: 'Cathrine Karlsson', workTitle: 'Licensed physical therapist' },
      { image: 'pt-elisabet', title: 'Elisabet Boulanger', workTitle: 'Licensed physical therapist' },
    ],
    listing: [
      {
        title: 'Ready to help you reach your goals',
        li: [
          { text: 'Certified experts on chronic joint pain' },
          { text: 'Available every day of the week' },
          { text: 'Ready to answer questions via chat' },
          { text: 'There for you throughout the treatment' },
        ],
        buttonText: 'Connect with a physical therapist',
      },
    ],
  },
  fr_fr: {
    title: 'Personal guidance and support',
    text: 'Our physical therapists are certified experts on joint pain and will help you get started.',
    icons: [
      { text: 'Live video',
        icon: 'video' },
      { text: 'Messaging',
        icon: 'comments' },
      { text: 'Phone',
        icon: 'phone' },
    ],
    slides: [
      { image: 'pt-1' },
      { image: 'pt-2' },
      { image: 'pt-3' },
      { image: 'pt-4' },
      { image: 'pt-5' },
    ],
    listing: [
      {
        title: 'Ready to help you reach your goals',
        li: [
          { text: 'Certified experts on chronic joint pain' },
          { text: 'Available every day of the week' },
          { text: 'Ready to answer questions via chat' },
          { text: 'There for you throughout the treatment' },
        ],
        buttonText: 'Connect with a physical therapist',
      },
    ],
  },
};
