/* eslint-disable react/no-array-index-key */
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/pro-duotone-svg-icons';
import { faPhone, faComments, faVideo } from '@fortawesome/free-solid-svg-icons';
import FiveStarsImage from '@/static/icon_fivestars.svg';

import ImageNoTraceSvg from '../../image';
// Use icons from our library
import contentData from './data';
import '../../../utils/fontawesome';

const LicensedPtSliderST = ({
  lang,
  downloadhref,
  downloadclassname,
  downloaddataGoal,
  downloadtext,
  storybook,
  smallTopSpacing = false,
}) => {
  const language = typeof lang === 'undefined' ? 'se_sv' : lang;
  const main = contentData[language];

  return (
    <section data-theme="white" className="LicensedPtSliderST">
      <div className={`container container--md margin-top--${smallTopSpacing ? 'sm' : 'lg'} margin-bottom--lg`}>
        <div key={main}>
          <div className="text--component margin-bottom--sm text--center">
            <h2>{main.title}</h2>
            <p>{main.text}</p>
            <div className="flex margin-top--xs fontawesomeIcons">
              {main.icons
                && main.icons.map((icon) => (
                  <div className="flex" style={{ alignItems: 'center' }} key={icon.icon}>
                    {icon.icon === 'video' && <FontAwesomeIcon className="margin-right--xxs" icon={faVideo} />}
                    {icon.icon === 'comments' && <FontAwesomeIcon className="margin-right--xxs" icon={faComments} />}
                    {icon.icon === 'phone' && <FontAwesomeIcon className="margin-right--xxs" icon={faPhone} />}

                    <p style={{ alignItems: 'center' }}>{icon.text}</p>
                  </div>
                ))}
            </div>
          </div>
          <div className="grid grid-gap--lg flex--center flex--space-between-lg">
            <div className="col col--12 col--sm-5">
              <div className="swiper-container swiper-container--fade">
                <div className="swiper-wrapper">
                  {main.slides
                    && main.slides.map((slide, index) => (
                      <div key={`pt-slide-${index}`} className="swiper-slide">
                        <div className="pt-slider__card">
                          <div className="pt-slider__card-content">
                            <ImageNoTraceSvg
                              filename={slide.image}
                              alt={slide.image}
                            />

                            {slide?.title && (
                              <div className="pt-slider__card-info">
                                <p className="text--bolder">{slide.title}</p>
                                <p className="text--small margin-bottom--xxs">{slide.workTitle}</p>

                                <img src={FiveStarsImage} alt="5" height={20} />
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            </div>
            <div className="col col--12 col--sm-6">
              {main.listing
                && main.listing.map((list) => (
                  <div key={list.title} className="text--component">
                    <h3>{list.title}</h3>
                    <div className="margin-bottom--sm liContainer">
                      {list.li
                        && list.li.map((li) => (
                          <div
                            key={li.text}
                            className="flex margin-top--sm"
                          >
                            <FontAwesomeIcon icon={faCheckCircle} />
                            <p className="text--md">{li.text}</p>
                          </div>
                        ))}
                    </div>
                    <h3 className="margin-top--md">{list.buttonText}</h3>
                    <a
                      href={downloadhref}
                      className={downloadclassname}
                      data-goal={downloaddataGoal}
                    >
                      {downloadtext}
                    </a>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default LicensedPtSliderST;
